import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

import { Button } from '~/components/shared/inputs';
import RaisedShadow from '~/components/shared/RaisedShadow';

import type { Game, CheckoutStatuses } from '../types';
import { Feature, isFeatureEnabled } from '~/lib/Feature';
import BetaGameItem from './BetaGameItem';
import { usePath } from '~/hooks';

type GameItemProps = {
  game: Game;
  platformName: string;
  isReadOnly: boolean;
  checkoutStatuses: CheckoutStatuses;
  requestCheckout?: (id: string) => void;
};

function GameItem(props: GameItemProps) {
  const { game, platformName, isReadOnly, checkoutStatuses, requestCheckout } =
    props;
  const { title, isFinished = false, checkoutStatusId } = game;
  const checkoutStatus = checkoutStatuses.find(
    (c) => c.id.toString() === checkoutStatusId,
  );
  const path = usePath(game.id);

  function handleRequestCheckout() {
    if (game.id && requestCheckout) requestCheckout(game.id);
  }

  const content = (
    <RaisedShadow>
      <div>{title}</div>
      <div>{platformName}</div>
      {isReadOnly &&
        (!checkoutStatus ? (
          <div className="absolute right-12 bottom-2">
            <Button onClick={handleRequestCheckout}>Request checkout</Button>
          </div>
        ) : (
          <div className="absolute right-12 bottom-2">
            {checkoutStatus.label}
          </div>
        ))}
      {isFinished && (
        <div className="absolute text-primary top-2 right-2">
          <FontAwesomeIcon size="lg" icon={faCheckCircle} />
        </div>
      )}
    </RaisedShadow>
  );

  return isFeatureEnabled(Feature.Card) ? (
    <BetaGameItem game={game} platformName={platformName} />
  ) : (
    <div className="max-w-full flex-auto flex-shrink-0">
      {isReadOnly ? (
        content
      ) : (
        <Link to={path} state={{ game }}>
          {content}
        </Link>
      )}
    </div>
  );
}

export default GameItem;
